<template>
  <b-card no-body title="Queued">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="500" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1">
          <b-button-group class="mr-2 mb-1">
            <b-form-checkbox id="checkbox-show-all" v-model="showAll" name="checkbox-show-all" disabled> Show all </b-form-checkbox>
          </b-button-group>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" @click="initalLoadQueue" v-b-tooltip.hover title="Refresh">
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table responsive :fields="fields" :items="getQueued" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="isSortDesc" sort-icon-left no-local-sorting>
        <template #cell(name)="data">
          <template v-if="data.item.mediaCount > 0">
            <b-link v-model="data.detailsShowing" @click="loadDocs(data)">
              {{ data.value ? data.value : "Name not set" }}
            </b-link>
          </template>
          <template v-else>
            {{ data.value ? data.value : "Name not set" }}
          </template>
          <br />
          {{ data.item.email }}<br />
          {{ data.item.phone }}
        </template>
        <template #cell(company)="data">
          <b>{{ data.value }}</b
          ><br />
          <i>{{ data.item.role }}</i>
        </template>
        <template #cell(notes)="data">
          {{ data.item.crmData && data.item.crmData.notes }}
        </template>
        <template #cell(samples)="data">
          <template v-if="data.item.sampleItems && data.item.sampleItems.length > 0">
            <ol>
              <li v-for="(s, i) of data.item.sampleItems" :key="i">{{ s.brand }} [{{ s.product }}]</li>
            </ol>
          </template>
        </template>

        <template #row-details="row">
          <b-row>
            <b-col md="1" />
            <b-col md="9">
              Documents for : {{ row.item.name }} <br />

              <b-col v-for="(docItem, index) of allDocs" lg="6" :key="docItem.id">
                <div class="accordion" role="tablist">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle="'accordion-' + index" variant="info">{{ docItem.originalFileName }}</b-button>
                    </b-card-header>
                    <b-collapse :id="'accordion-' + index" :accordion="'my-accordion-' + index" role="tabpanel" @shown="getDocument(docItem)">
                      <b-card-body :key="index">
                        <b-overlay :show="getLoading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                          <b-card-img bottom v-bind:src="docItem.docImage" :alt="docItem.originalFileName" :key="updated"></b-card-img>
                        </b-overlay>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </b-col>
            </b-col>
          </b-row>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>

        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  loadQueue,
  processQueued,
  sortBy,
  sortOrder,
  setPerPage,
  getLoading,
  getTotalRows,
  getPerPage,
  filterBy,
  getQueued,
  loadHOFContactDocsList,
  loadDocumentImage
} from "./userRequests";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { onMounted, ref, watch } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "QueuedContacts",
  components: {},
  directives: {
    Ripple
  },
  setup(_, { root }) {
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const currentPage = ref(1);
    const isSortDesc = ref(false);
    const showAll = ref(true);
    const allDocs = ref([]);
    const updated = ref(1);

    const fields = [
      // 'mark_resolved',
      "name",
      "company",
      "samples",
      "notes",
      { key: "mediaCount", label: "# Images" },
      { key: "companyCategory", label: "Buyer?" }
    ];

    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");

    const initalLoadQueue = async () => {
      const ret = await loadQueue({ prevPage: false, nextPage: false, reset: true, showAll: true });
      if (ret) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `Error getting queue ${ret}!`
          }
        });
      }
    };

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadQueue();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await loadQueue({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await loadQueue({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await loadQueue({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    onMounted(() => {
      filterBy.value = "";
      isSortDesc.value = false;
      initalLoadQueue();
    });

    const qeueuAt = (epoch) => {
      const d = new Date(Number(epoch || 0) * 1000);
      return showDateInFormat(d);
    };

    const contactServiced = async (cont) => {
      console.log("Handling", cont);
      await processQueued(cont.uuid);
    };

    const loadDocs = async (datax) => {
      datax.toggleDetails();
      const { data } = await loadHOFContactDocsList(datax.item.uuid);
      if (data && data.documents) {
        for (let d of data.documents) {
          d.docImage = "";
        }
        allDocs.value = data.documents;
      }
    };

    const getDocument = async (doc) => {
      console.log("getting", doc);
      const data = await loadDocumentImage({ mediaUid: doc.guuid });
      doc.docImage = data;
      updated.value = updated.value + 1;
    };

    watch(filterBy, (newValue, oldValue) => {
      initalLoadQueue();
    });

    watch(showAll, (newValue, oldValue) => {
      initalLoadQueue();
    });

    watch(sortBy, (newValue, oldValue) => {
      initalLoadQueue();
    });

    watch(isSortDesc, (newValue, oldValue) => {
      sortOrder.value = newValue ? "desc" : "asc";
      initalLoadQueue();
    });

    return {
      getQueued,
      initalLoadQueue,
      filterBy,
      setPerPage,
      getPerPage,
      getLoading,
      getTotalRows,
      fields,
      currentPage,
      pageOptions,
      showDateInFormat,
      onPageChanged,
      perPageChanged,
      sortBy,
      sortOrder,
      isSortDesc,
      loadQueue,
      contactServiced,
      showAll,
      qeueuAt,
      loadDocs,
      allDocs,
      getDocument
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
